.App {
  font-family: "abc", Open Sans, system-ui, sans-serif;
  font-size: 15px;
  line-height: 1.7;
  letter-spacing: 0.1px;
  -webkit-font-smoothing: antialiased;
}

.carrot {
  transition: 150ms ease-in;
}

.carrot-active {
  transform: rotate(180deg);
}

.dropdown {
  overflow: hidden;
  display: none;
}

.dropdown-active {
  display: block;
}

.dropdown-item {
  transition: 150ms ease-in;
}

.verification-dropdown-item {
  cursor: unset !important;
}

.dropdown-item:hover {
  opacity: 0.6;
}

.button {
  transition: 150ms ease-in;
}

.button:hover {
  opacity: 0.6;
}

.help-center-button {
  background: #ffcfba;
  padding: 30px;
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  margin: 0 30px;
}

.help-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.switch-button-container {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  border: "1px solid #e1e1e1";
  background-color: #e1e1e1;
  border-radius: 106px;
}
.switch-button {
  padding: 5px 10px;
  margin: 2px;
  transition: 150ms ease-in-out;
  border-radius: 16px;
  font-size: 12px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.switch-button-active {
  background-color: #fff;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  z-index: 9999;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  width: 375px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #faf9f7 !important;
}

@media only screen and (max-width: 630px) {
  .ReactModal__Content {
    width: 80%;
  }

  .help-center-button {
    width: calc(100% - 60px);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .help-center-container {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid rgb(225, 225, 225);
  padding: 10px 5px;
  border-radius: 6px;
  font-size: 16px;
}
