body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #242424;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-family: "gtmr", "abc", Open Sans, system-ui, sans-serif;
  letter-spacing: -1px;
}

body {
  background-color: #fff7ef;
}

.table {
  margin-right: 10px;
  max-width: 1024px;
  max-height: 90vh;
  display: "block";
  overflow-y: "auto";
  overflow-x: "auto";
  background-color: #faf9f7 !important;
  border-color: "#000" !important;
}
.headerCells {
  background-color: #faf9f7 !important;
  font-weight: bold !important;
  color: #62605d !important;
}
.bodyCells {
  color: #62605d !important;
  border-bottom: 1px solid #faf9f7 !important;
}

@font-face {
  font-family: "gtmr";
  src: url("./assets/fonts/gtmrblk.woff2") format("woff2"),
    url("./assets/fonts/gtmrblk.woff") format("woff"),
    url("./assets/fonts/gtmrblk.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "gtmr";
  src: url("./assets/fonts/gtmrbld.woff2") format("woff2"),
    url("./assets/fonts/gtmrbld.woff") format("woff"),
    url("./assets/fonts/gtmrbld.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "gtmr";
  src: url("./assets/fonts/gtmrmd.woff2") format("woff2"),
    url("./assets/fonts/gtmrmd.woff") format("woff"),
    url("./assets/fonts/gtmrmd.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "abc";
  src: url("./assets/fonts/abcr.woff2") format("woff2"),
    url("./assets/fonts/abcr.woff") format("woff"),
    url("./assets/fonts/abcr.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "abc";
  src: url("./assets/fonts/abcb.woff2") format("woff2"),
    url("./assets/fonts/abcb.woff") format("woff"),
    url("./assets/fonts/abcb.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
}

div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

a {
  color: #ff7c7c;
  text-decoration: none;
}

input {
  color: #242424;
}

input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
